/* eslint-disable object-curly-spacing */
/* eslint-disable require-jsdoc */
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import './App.css';
import {Home} from './pages/Home/Home.jsx';
import {Login} from './pages/Login/Login';
import {Register} from './pages/Register/Register';
import {Dashboard} from './pages/Dashboard/Dashboard.jsx';
import {VerifyEmail} from './pages/Register/VerifyEmail';
import {TellUsAboutYou} from './pages/Dashboard/TellUsAboutYou.jsx';
import { ResetPassword } from './pages/Login/ResetPassword';
import { VerificationStatus } from './pages/Register/VerificationStatus';
import {Pricing} from './pages/Pricing/Pricing';
import { ProCheckout } from './pages/Pricing/ProCheckout';
import { Success } from './pages/Pricing/Success';
import {Loading} from './pages/Loading/Loading.jsx';

function App() {
  return (
    <div>


      <Router>
        <Routes>
          <Route path="/" element={<Loading />}/>
          <Route path="/home" element={<Home />}/>
          <Route path="/Login" element={<Login />}/>
          <Route path = "Register" element={<Register />}/>
          <Route path = "Dashboard" element={<Dashboard />}/>
          <Route path = "verify-email" element={<VerifyEmail />}/>
          <Route path="/tell-us-about-you" element={<TellUsAboutYou />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verification" element={<VerificationStatus />}/>
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/pro-checkout" element={<ProCheckout />} />
          <Route path="/success" element={<Success />} />

        </Routes>
      </Router>

    </div>

  );
}

export default App;
