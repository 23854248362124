/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'; // Removed spaces inside curly braces

import './Home.css'; // Assuming you saved the above CSS in Home.css
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import cursor from '../../components/cursor.png';

import Summon from '../../components/Summon.gif'; // Import the GIF
import Dismiss from '../../components/Dismiss.gif'; // Import the GIF
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image

export const Home = () => {
  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      // Adjust the cursor position relative to the scroll position
      const x = e.clientX + window.scrollX;
      const y = e.clientY + window.scrollY;
      cursorImage.style.left = `${x}px`;
      cursorImage.style.top = `${y}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  return (
    <div>
      <nav className="navbar">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Seda Logo" /> {/* Logo image */}
        </a>
        <div className="navbar-links">
          <Link to="/pricing" className="nav-link">Pricing</Link>
          <Link to="/register" className="signup-button">Sign up</Link>
          <Link to="/login" className="login-button">Log In</Link>
        </div>
      </nav>

      <div className="main-content">
        <div className="text-container">
          <h1>The AI Powered Cursor</h1>
          <p className="tagline">Your Personal Assistant for surfing the internet</p>
          <p className="description">
            Summon your AI in the browser, in your desktop applications, in your files, <span style={{fontStyle: 'italic', fontSize: '28p'}}>wherever you are</span> {/* Removed spaces inside curly braces */}
          </p>
          <div className="main-action">
            <Link to="/register" className="signup-button">Sign Up</Link>
            <Link to="/login" className="login-button">Log In</Link>
          </div>
        </div>
        <div className="cursor-container">
          <img src={cursor} alt="Seda cursor" className="cursor-img" />
        </div>
      </div>
      <div className="main-content2">
        <div className="video-container">
          <img src={Summon} alt="Description of GIF" width="10%" />
        </div>
        <div className="text-container">
          <h1>Hold Command and Left <br />Click to Summon Your AI</h1>
        </div>
      </div>
      <div className="main-content3">
        <div className="text-container">
          <h1>Dismiss your AI with<br /> Command + Left Click</h1>
        </div>
        <div className="video-container">
          <img src={Dismiss} alt="Description of GIF" width="100%" />
        </div>
      </div>
      <div className="main-content4">
        <h1>AI at your fingertips, wherever you are</h1>
        <div className="main-action">
          <Link to="/register" className="signup-button">Sign Up</Link>
          <Link to="/login" className="login-button">Log In</Link>
        </div>
      </div>
      <div className="home-gradient-bar"></div> {/* Gradient bar now at the top */}
      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />
      <footer className="custom-footer">
        <div className="footer-container">
          <a href="/" className="navbar-logo">
            <img src={logo} alt="Seda Logo" /> {/* Logo image */}
          </a>
          <div className="footer-links">
            <a href="/about">About</a>
            <a href="/contact">Contact Us</a>
            <a href="/careers">Careers</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
