/* eslint-disable no-restricted-globals */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import './Login.css';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image


export const Login = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginMessage, setLoginMessage] = useState(''); // State to hold the login message
  const [isSubmitting, setIsSubmitting] = useState(false); // State to prevent multiple submits


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) return; // Prevent form submission if already submitting
    setIsSubmitting(true); // Set isSubmitting to true to prevent further submissions

    try {
      const response = await Axios.post(`${baseUrl}/login`, {
        username: email,
        password,
      });
      setTimeout(() => { // Use setTimeout to delay the execution
        if (response.data.message) {
          setLoginMessage(response.data.message); // Update the state with the login message
        } else {
          const {user, token, accountInfoCreated} = response.data;
          console.log(user);
          console.log(user.isVerified);

          if (!user.isVerified) {
            navigate('/verify-email', {state: {username: email, password: password}});
          } else if (!accountInfoCreated) {
            navigate('/tell-us-about-you', { state: {token} });
          } else {
            navigate('/dashboard', { state: {token, email} });
          }
        }
        setIsSubmitting(false); // Reset isSubmitting to false after handling response
      }, 1000); // Delay execution by 2000 milliseconds (2 seconds)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setLoginMessage(error.response.data.message); // Update the state with the backend error message
      } else {
        setLoginMessage('An unexpected error occurred.');
      }
      setIsSubmitting(false); // Reset isSubmitting on error as well
      console.error(error);
    }
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const response = await Axios.get(`${baseUrl}/login`);
        if (response.data.loggedIn) {
          console.log(response.data.loggedIn);
        } else {
          console.log(response.data.loggedIn);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkLoggedIn();
  }, []);

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  const handlePasswordReset = async () => {
    if (!email) {
      alert('Please enter your email address to reset your password.');
      return;
    }

    try {
      const response = await Axios.post(`${baseUrl}/password-reset/request-reset`, { email });
      alert(response.data.message);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="Login-App">
      <nav className="navbar">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Seda Logo" /> {/* Logo image */}
        </a>
        <div className="navbar-links">
          <Link to="/pricing" className="signup-button">Pricing</Link>
          <Link to="/register" className="signup-button">Sign up</Link>
        </div>
      </nav>

      <div className="auth-form-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Log In</h2>
          <label htmlFor="email" className="email-label">Email Address</label>
          <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" required />
          <label htmlFor="password" className="password-label">Password</label>
          <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" id="password" name="password" required />
          <button type="submit" className="button">Continue</button>
          {loginMessage && <div className="login-message">{loginMessage}</div>}

        </form>
        <button onClick={handlePasswordReset} className="reset-button">Forgot Password?</button>

        {/* Removed the Full Name input to match the image */}
        {/* Removed the "Already have an account?" button to match the image */}
      </div>
      <div className="gradient-bar"></div> {/* Gradient bar now at the top */}
      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />

    </div>
  );
};
