/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import cursorFollowImage from '../../components/Cursor_Circle.png';
import './Success.css'; // Make sure to create this CSS file

const Base_url = process.env.REACT_APP_BASE_URL;

export const Success = () => {
  const [status, setStatus] = useState('Verifying payment...');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const session_id = queryParams.get('session_id');

  useEffect(() => {
    const verifySubscription = async () => {
      try {
        const email = localStorage.getItem('checkoutEmail');
        console.log('Email:', email);
        const response = await axios.post(`${Base_url}/stripe/verify-subscription`,
            { email },
            { params: { session_id } },
        );
        if (response.data.message === 'Subscription updated successfully') {
          setStatus('Payment successful! Your subscription has been updated.');
        } else {
          setStatus('There was an issue updating your subscription. Please contact support.');
        }
      } catch (error) {
        console.error('Error verifying subscription:', error);
        setStatus('There was an error processing your payment. Please contact support.');
      }
    };

    if (session_id) {
      verifySubscription();
    }
  }, [session_id]);

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  return (
    <div className="Success-App">
      <nav className="navbar">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Seda Logo" />
        </a>
      </nav>
      <div className="success-container">
        <h1>Subscription Status</h1>
        <p>{status}</p>
        <Link to="/" className="home-button">Return to Home</Link>
      </div>
      <div className="gradient-bar"></div>
      <img
        src={cursorFollowImage}
        id="cursor-image"
        className="cursor-image"
        alt="Cursor Follow"
      />
    </div>
  );
};

export default Success;
