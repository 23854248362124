/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import './Register.css';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import cursorFollowImage from '../../components/Cursor_Circle.png'; // Add your cursor follow image


export const Register = (props) => {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [name, setName] = useState('');
  const [responseMessage, setResponseMessage] = useState(''); // State to hold the response message

  Axios.defaults.withCredentials = true;
  const Base_url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage(''); // Clear previous message
    try {
      const response = await Axios.post(`${Base_url}/Register`, {
        username: email,
        password: pass,
      });
      // Set response message and handle navigation or display based on the response
      setResponseMessage(response.data.message);
      setTimeout(() => { // Delay for 1 second before taking action
        if (response.data.message === 'User created') {
          // If user is created, navigate to the verification page
          navigate('/verify-email', {state: {username: email, password: pass}});
        }
        // If user already exists, the message will be displayed above the password field
        // No further action is taken here for "User already Exists" as the message display is handled by state
      }, 1000);
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setResponseMessage('An error occurred during registration.'); // Handle error scenario
      }, 1000);
    }
  };

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);


  return (
    <div className="Register-App">
      <nav className="navbar">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Seda Logo" /> {/* Logo image */}
        </a>
        <div className="navbar-links">
          <Link to="/pricing" className="signup-button">Pricing</Link>
          <Link to="/login" className="login-button">Log In</Link>
        </div>
      </nav>

      <div className="auth-form-container">
        <form className="register-form" onSubmit={handleSubmit}>
          <h2>Sign Up</h2>
          <label htmlFor="email" className="email-label">Email Address</label>
          <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" required />
          <label htmlFor="password" className="password-label">Password</label>
          <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" id="password" name="password" required />
          <button type="submit" className="button">Continue</button>
          {responseMessage && <div className="response-message">{responseMessage}</div>}

        </form>

        {/* Removed the Full Name input to match the image */}
        {/* Removed the "Already have an account?" button to match the image */}
      </div>
      <div className="gradient-bar"></div> {/* Gradient bar now at the top */}
      <img src={cursorFollowImage} id="cursor-image" className="cursor-image" alt="Cursor Follow" />

    </div>
  );
};
