/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import cursorFollowImage from '../../components/Cursor_Circle.png';
import { loadStripe } from '@stripe/stripe-js';
import './ProCheckout.css';

const Base_url = process.env.REACT_APP_BASE_URL;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const ProCheckout = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors
    try {
      const stripe = await stripePromise;
      console.log('Sending request to create checkout session');
      localStorage.setItem('checkoutEmail', email);
      const response = await fetch(`${Base_url}/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        try {
          const errorData = JSON.parse(errorText);
          if (errorData.error === 'User is already subscribed to the pro plan') {
            setError('You are already subscribed to the pro plan.');
          } else {
            setError(errorData.error || 'Failed to create checkout session');
          }
        } catch (jsonError) {
          console.error('Error parsing JSON:', jsonError);
          setError('An unexpected error occurred. Please try again.');
        }
        return; // Exit the function early
      }
      const session = await response.json();
      console.log('Received session:', session);
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        console.error('Stripe redirect error:', result.error);
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  return (
    <div className="ProCheckout-App">
      <nav className="navbar">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Seda Logo" />
        </a>
      </nav>
      <div className="checkout-form-container">
        <form onSubmit={handleSubmit}>
          <h2>Enter your email to continue</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
            required
          />
          {error && <div className="error-message">{error}</div>}
          <button type="submit">Continue to Checkout</button>
        </form>
      </div>
      <div className="gradient-bar"></div>
      <img
        src={cursorFollowImage}
        id="cursor-image"
        className="cursor-image"
        alt="Cursor Follow"
      />
    </div>
  );
};

export default ProCheckout;
