/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import './Pricing.css';
import logo from '../../components/Final-Seda-Logo-rectangle-removebg-preview.png';
import cursorFollowImage from '../../components/Cursor_Circle.png';
import {loadStripe} from '@stripe/stripe-js';
const Base_url = process.env.REACT_APP_BASE_URL;

const stripePromise = loadStripe('your_stripe_publishable_key');

export const Pricing = () => {
  useEffect(() => {
    const cursorImage = document.getElementById('cursor-image');
    const moveCursor = (e) => {
      cursorImage.style.left = `${e.clientX}px`;
      cursorImage.style.top = `${e.clientY}px`;
    };
    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, []);

  const navigate = useNavigate();

  const handleProPurchase = () => {
    navigate('/pro-checkout');
  };

  return (
    <div className="Pricing-App">
      <nav className="navbar">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="Seda Logo" />
        </a>
        <div className="navbar-links">
          <Link to="/register" className="signup-button">Sign up</Link>
          <Link to="/login" className="login-button">Log In</Link>
        </div>
      </nav>

      <div className="pricing-content">
        <h1>Super Charge The Internet</h1>
        <div className="pricing-cards">
          <div className="pricing-card">
            <div className="card-header">
              <h2>Free</h2>
              <p>Your Personal Assistant for Surfing the web</p>
            </div>
            <div className="price">$0 <span>USD/mo</span></div>
            <button className="get-started-button">Get Started</button>
            <ul className="features">
              <li className="included">gpt 4o mini access</li>
              <li className="not-included">gpt 4o access</li>
              <li className="included">Summon your cursor wherever you are</li>
              <li className="included">Early Access to New Features</li>
              <li className="included">Up to 5x more messages</li>
              <li className="included">Token Count</li>
              <li className="included">Cursor Specific Functionality</li>
            </ul>
          </div>
          <div className="pricing-card">
            <div className="card-header">
              <h2>Pro</h2>
              <p>Supercharged Personal Assistant with advanced cursor specific functionality</p>
            </div>
            <div className="price">$20 <span>USD/mo</span></div>
            <button className="get-started-button" onClick={handleProPurchase}>Get Started</button>
            <ul className="features">
              <li className="included">gpt 4o mini access</li>
              <li className="included">gpt 4o access</li>
              <li className="included">Summon your cursor wherever you are</li>
              <li className="included">Early Access to New Features</li>
              <li className="included">Up to 5x more messages</li>
              <li className="included">Token Count</li>
              <li className="included">Cursor Specific Functionality</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="gradient-bar"></div>
      <img
        src={cursorFollowImage}
        id="cursor-image"
        className="cursor-image"
        alt="Cursor Follow"
      />
    </div>
  );
};

export default Pricing;
